import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import mova from 'mova';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '@state/ducks/user';
import Icon from '@components/Icon/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import styled from '@emotion/styled';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { placeSelectors } from 'state/ducks/place';
import { Divider, Popover, Tooltip, useTheme } from '@mui/material';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import RejectReasonModal from './components/RejectReasonModal';
import FinishReservationModal from './components/FinishReservationModal';
import { reservationActions, reservationThunks } from 'state/ducks/reservation';
import useModal from '@utils/hooks/useModal/useModal';
import AcceptReservationModal from './components/AcceptReservationModal';
import { getReservationColor } from 'utils/utils';
import { isDefined } from 'utils/lo/lo';
import { alpha } from '@mui/material/styles';
import ReservationHistoryModal from './components/ReservationHistoryModal';
import { getReservationDuration, nearest15Minutes } from "utils/date";
import { linkify } from "utils/url";
import { lineClamp } from "utils/styles";
import PreviewMenuModal from "./components/PreviewMenuModal";

const t = mova.ns('components.Reservations');

const StyledRootBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: theme.shadow,
  backgroundColor: theme.palette.common.white,
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  top: 50,
  borderRadius: theme.shape.borderRadius,
}));

const StyledCommentIcon = styled(Box)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.main,
}));

const StyledActionIcon = styled(Box)(({ theme, primary }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50%',
  backgroundColor: primary ? theme.palette.secondary.main : theme.palette.common.white,
  borderLeft: `1px solid ${primary ? theme.palette.secondary.main : theme.palette.typography.extraLight}`,
  cursor: 'pointer',
}));

const ReservationCard = ({ reservation, width, onEdit, smallView, ...rest }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [waitListModalOpen, deleteFromWaitList, closeWaitListModal] = useModal(false);
  const [acceptModalOpen, acceptReservation, closeAcceptModal] = useModal(false);
  const [rejectModalOpen, rejectReservation, closeRejectModal] = useModal(false);
  const [skipModalOpen, skipReservation, closeSkipModal] = useModal(false);
  const [finishModalOpen, finishReservation, closeFinishModal] = useModal(false);
  const [inProgressModalOpen, moveToInProgress, closeInProgressModal] = useModal(false);
  const [updateBillModalOpen, updateBillAmount, closeUpdateBillModal] = useModal(false);
  const [cancelModalOpen, cancelReservation, closeCancelModal] = useModal(false);
  const [backToAcceptedModalOpen, backToAccepted, closeBackToAcceptedModal] = useModal(false);
  const [historyModalOpen, showHistory, closeHistoryModal] = useModal(false);
  const [menuPreviewOpen, openMenuPreview, closeMenuPreview] = useModal(false);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [highlightCounter, setHighlightCounter] = useState(0); // eslint-disable-line no-unused-vars
  const newReservation = reservation.status === 'REQUESTED';
  const reservationAccepted = reservation.status === 'ACCEPTED';
  const reservationInProgress = reservation.status === 'INPROGRESS';
  const reservationRejected = reservation.status === 'REJECTED';
  const reservationFinished = reservation.status === 'FINISHED';
  const reservationSkipped = reservation.status === 'SKIPPED';
  const reservationCancelled = reservation.status === 'CANCELED';
  const reservationWaitList = reservation.status === 'WAITLIST';
  const dispatch = useDispatch();
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const customTags = useSelector(placeSelectors.getCustomTags());

  const reservationDate = moment(reservation.from).format('DD.MM.YY');
  const today = useMemo(() => moment().format('DD.MM.YY'), []);
  const tomorrow = useMemo(() => moment().add(1, 'days').format('DD.MM.YY'), []);
  const reservationDateText = reservationDate === tomorrow ? t('tomorrow') : reservationDate;
  const minutesSinceStart = moment().diff(moment(reservation.from), 'minutes');
  const durationString = getReservationDuration(minutesSinceStart, t);
  const requireConfirmation = activePlace.billTypeExtra?.includes('highload') && activePlace.showReservationConfirmed
    && !reservation.confirmed;

  const preferencesFilled = reservation.user?.hasIngridients;
  const allergiesFilled = reservation.user?.hasAllergy;
  const userHasComment = reservation.user?.hasNote;
  const reservationHasMenu = reservation.bill?.data?.order?.dishes;

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const incrementHighlightCounter = () => setHighlightCounter(c => c + 1);

  useEffect(() => {
    const intervalId = setInterval(incrementHighlightCounter, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const updateReservationStatus = ({ callback, ...extraValues }) => {
    dispatch(
      reservationThunks.updateReservation({
        id: reservation.id,
        callback,
        ...extraValues,
      }),
    );
  };

  const confirmAcceptReservation = values => {
    const body = {
      statusFrom: reservation.status,
      statusTo: reservationWaitList ? 'INPROGRESS' : 'ACCEPTED',
      id: reservation.id,
      hallId: values?.hallId ? values?.hallId : undefined,
      spots: values?.spots ? values?.spots : undefined,
      event: values?.event || undefined,
      depositOrder: values?.depositOrder || undefined,
      depositUpdated: values?.depositUpdated,
    }

    if (reservationWaitList) {
      body.from = moment().toDate();
      body.to = activePlace.reservationAllDay
        ? moment()
          .set('hour', activePlace.openHours[moment().isoWeekday() - 1].closedH)
          .set('minute', activePlace.openHours[moment().isoWeekday() - 1].closedM)
          .toDate()
        : nearest15Minutes(moment())
          .add(activePlace?.reservationDuration || 120, 'minutes')
          .toDate();
    }

    updateReservationStatus({
      ...body,
      callback: closeAcceptModal,
    });
  };

  const confirmSkipReservation = () => {
    updateReservationStatus({
      statusFrom: reservation.status,
      statusTo: 'SKIPPED',
      id: reservation.id,
      callback: closeSkipModal,
      feedback: 'adminSkipped',
    });
  };

  const confirmFinishReservation = ({ billAmount } = {}) => {
    updateReservationStatus({
      statusFrom: reservation.status,
      statusTo: 'FINISHED',
      id: reservation.id,
      callback: closeFinishModal,
      billAmount,
    });
  };

  const confirmCancelReservation = () => {
    updateReservationStatus({
      statusFrom: reservation.status,
      statusTo: 'CANCELED',
      id: reservation.id,
      callback: closeCancelModal,
      feedback: 'adminCancelled',
    });
  };

  const confirmUpdateBill = ({ billAmount } = {}) => {
    updateReservationStatus({
      statusTo: 'FINISHED',
      id: reservation.id,
      callback: closeUpdateBillModal,
      billAmount,
    });
  };

  const confirmMoveToInProgress = () => {
    updateReservationStatus({
      statusFrom: reservation.status,
      statusTo: 'INPROGRESS',
      id: reservation.id,
      callback: closeInProgressModal,
    });
  };

  const confirmBackToAccepted = () => {
    updateReservationStatus({
      statusFrom: reservation.status,
      statusTo: 'ACCEPTED',
      id: reservation.id,
      callback: closeBackToAcceptedModal,
    });
  };

  const confirmAdditionalConfirmation = () => {
    updateReservationStatus({
      id: reservation.id,
      confirmed: true,
    });
  };

  const confirmDeleteFromWaitList = () => {
    updateReservationStatus({
      id: reservation.id,
      statusFrom: reservation.status,
      statusTo: 'DRAFTED',
      callback: closeWaitListModal,
    });
  };

  const openUserModal = useCallback(() => {
    dispatch(userActions.setUserInModal(reservation.user));
  }, [dispatch, reservation.user]);

  const reservationHighlighted =
    ['REQUESTED', 'ACCEPTED'].includes(reservation.status) &&
    isDefined(activePlace.highlightBefore) &&
    moment().add(activePlace.highlightBefore, 'minutes') > moment(reservation.from);

  const editReservation = () => {
    dispatch(reservationActions.openReservationDrawer({ reservation }));
    onEdit && onEdit();
  }

  const handleCommentClick = (e) => {
    e.stopPropagation();
    setPopoverAnchor(e.currentTarget);
  }

  const handleCloseComment = (e) => {
    e.stopPropagation();
    setPopoverAnchor(null);
  }

  const billDisplay = (
    <Tooltip title={`${reservation.bill?.amount} ${t('uah')}`}>
      <Icon
        type='dollar'
        size={16}
        color={reservation.bill?.status === 'DONE' ? theme.palette.success.main : theme.palette.primary.light}
      />
    </Tooltip>
  )

  const editMenu = () => {
    closeMenuPreview();
    editReservation();
  }

  return (
    <StyledRootBox width={width || 320} {...rest}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        gap={0.5}
        flexGrow={0}
        flexShrink={0}
        flexBasis={preferencesFilled || allergiesFilled || userHasComment || reservationHasMenu ? 24 : 8}
        backgroundColor={getReservationColor(reservation.event)}
        title={customTags[reservation.event || 'other'] || t(`events.${reservation.event || 'other'}`)}
        onClick={openUserModal}
      >

        {reservationHasMenu && <Icon type='dishes' size={16} />}
        {allergiesFilled && <Icon type='leaf' />}
        {preferencesFilled && <Icon type='heart' />}
        {userHasComment && <Icon type='commentFilled' />}
      </Box>
      <Box
        flexGrow={1}
        minWidth={0}
        p={1}
        pr={2}
        backgroundColor={reservationHighlighted ? alpha(theme.palette.secondary.main, 0.15) : 'white'}
      >
        <Box position='relative' pr={3} mb={0.5}>
          {(reservation.user || reservation.variables?.user) && smallView && (
            <Box
              sx={{ cursor: 'pointer' }}
              display='flex'
              alignItems='center'
              gap={1}
              onClick={openUserModal}
            >
              {reservation.user?.phone && (
                <Link
                  href={`tel:${reservation.user?.phone}`}
                  sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: 0.5 }}
                  onClick={e => e.stopPropagation()}
                >
                  <Icon type='phone' size={20} />
                  <Typography variant='h4' color='typography.info'>
                    ...{reservation.user.phone.slice(-4)}
                  </Typography>
                </Link>
              )}
              {reservation.variables?.keepTable && <Icon type='star' color='#FFCE31' size={16} filled />}
              {reservation.user?.isSlacker && <Icon type='flag' color={theme.palette.error.main} size={16} />}
              <Typography
                variant='body1'
                color='primary'
                noWrap
                sx={{ flexGrow: 1 }}
                title={reservation.user?.displayedName || reservation.variables?.user?.displayedName}
              >
                {reservation.user?.displayedName || reservation.variables?.user?.displayedName}
              </Typography>
              {reservation.type === 'guest' && (
                <Icon type='logo' />
              )}
              {reservation.bill?.data?.order?.length > 0 && billDisplay}
              {reservation.comment && (
                <>
                  <Box
                    p={1}
                    onClick={handleCommentClick}
                    aria-owns={anchorEl ? 'comment-popover' : undefined}
                    aria-haspopup='true'
                  >
                    <StyledCommentIcon />
                  </Box>
                  <Popover
                    id='comment-popover'
                    open={!!popoverAnchor}
                    anchorEl={popoverAnchor}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    onClose={handleCloseComment}
                  >
                    <Typography
                      sx={{ p: 1, pointerEvents: 'auto' }}
                      dangerouslySetInnerHTML={{ __html: linkify(reservation.comment) }}
                    />
                  </Popover>
                </>
              )}
            </Box>
          )}
          {(reservation.user || reservation.variables?.user) && !smallView && (
            <Box>
              <Box
                sx={{ cursor: 'pointer' }}
                display='flex'
                alignItems='center'
                gap={1}
                onClick={openUserModal}
              >
                {reservation.user?.phone && (
                  <Link
                    href={`tel:${reservation.user?.phone}`}
                    sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: 0.5 }}
                    onClick={e => e.stopPropagation()}
                  >
                    <Icon type='phone' size={20} />
                    <Typography variant='h4' color='typography.info'>{reservation.user.phone}</Typography>
                  </Link>
                )}
                {reservation.variables?.keepTable && <Icon type='star' color='#FFCE31' size={16} filled />}
                {reservation.user?.isSlacker && <Icon type='flag' color={theme.palette.error.main} size={16} />}
                {reservation.type === 'guest' && <Icon type='logo' />}
                {reservation.bill?.data?.order?.length > 0 && billDisplay}
              </Box>
              <Typography
                variant='body1'
                color='primary'
                noWrap
                sx={{ flexGrow: 1, cursor: 'pointer' }}
                onClick={openUserModal}
              >
                {reservation.user?.displayedName || reservation.variables?.user?.displayedName}
              </Typography>
            </Box>
          )}
          {!reservation.user && !reservation.variables?.user && (
            <Typography variant='body1' color='primary' noWrap>
              {t('unknownUser')}
            </Typography>
          )}
          {!smallView && reservation.comment && (
            <Box>
              <Divider sx={{ my: 1 }} />
              <Typography
                variant='body2'
                dangerouslySetInnerHTML={{ __html: linkify(reservation.comment) }}
                style={lineClamp(2)}
                title={reservation.comment}
              />
              <Divider sx={{ my: 1 }} />
            </Box>
          )}
          <IconButton sx={{ position: 'absolute', top: -3, right: -12 }} onClick={openMenu}>
            <Icon type='dots' size={16} color={theme.palette.typography.info} />
          </IconButton>
          <StyledMenu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            onClick={closeMenu}
          >
            <Link href={`tel:${reservation.user?.phone}`} sx={{ textDecoration: 'none' }}>
              <MenuItem>
                <ListItemIcon>
                  <Icon type='phone' color='black' />
                </ListItemIcon>
                <ListItemText>{t('callClient')}</ListItemText>
              </MenuItem>
            </Link>
            {!reservationFinished && (
              <MenuItem onClick={editReservation}>
                <ListItemIcon>
                  <Icon type='edit' />
                </ListItemIcon>
                <ListItemText>{t('edit')}</ListItemText>
              </MenuItem>
            )}
            {reservationHasMenu && (
              <MenuItem onClick={openMenuPreview}>
                <ListItemIcon>
                  <Icon type='dishes' color={theme.palette.primary.main} />
                </ListItemIcon>
                <ListItemText>{t('viewMenu')}</ListItemText>
              </MenuItem>
            )}
            {reservationAccepted && (
              <MenuItem onClick={rejectReservation}>
                <ListItemIcon>
                  <Icon type='close' />
                </ListItemIcon>
                <ListItemText>{t('reject')}</ListItemText>
              </MenuItem>
            )}
            {reservationAccepted && (
              <MenuItem onClick={cancelReservation}>
                <ListItemIcon>
                  <Icon type='user' color={theme.palette.primary.main} />
                </ListItemIcon>
                <ListItemText>{t('clientCancelled')}</ListItemText>
              </MenuItem>
            )}
            {reservationRejected && (
              <MenuItem onClick={acceptReservation}>
                <ListItemIcon>
                  <Icon type='done' color={theme.palette.primary.main} />
                </ListItemIcon>
                <ListItemText>{t('accept')}</ListItemText>
              </MenuItem>
            )}
            {reservationSkipped && (
              <MenuItem onClick={moveToInProgress}>
                <ListItemIcon>
                  <Icon type='userAccept' />
                </ListItemIcon>
                <ListItemText>{t('movingToInProgress')}</ListItemText>
              </MenuItem>
            )}
            {(reservationInProgress || reservationCancelled) && (
              <MenuItem onClick={backToAccepted}>
                <ListItemIcon>
                  <Icon type='check' />
                </ListItemIcon>
                <ListItemText>{t('backToAccepted')}</ListItemText>
              </MenuItem>
            )}
            {reservationFinished && (activePlace.donates?.length > 0 || activePlace.billType === 'enterprise') && (
              <MenuItem onClick={updateBillAmount}>
                <ListItemIcon>
                  <Icon type='edit' />
                </ListItemIcon>
                <ListItemText>{t('updateBillAmount')}</ListItemText>
              </MenuItem>
            )}
            <MenuItem onClick={showHistory}>
              <ListItemIcon>
                <Icon type='clock' color={theme.palette.text.primary} />
              </ListItemIcon>
              <ListItemText>{t('showHistory')}</ListItemText>
            </MenuItem>
          </StyledMenu>
        </Box>
        <Box display='flex' gap={1} alignItems='center' justifyContent='space-between'>
          <Box gap={0.25} display='flex'>
            <Icon type='user' color={theme.palette.typography.info} size={16} />
            <Typography>{reservation.seats}</Typography>
          </Box>
          {reservationAccepted && minutesSinceStart > 0 && (
            <Box gap={0.25} display='flex'>
              <Icon type='userCancel' color={theme.palette.typography.info} size={16} />
              <Typography>{durationString}</Typography>
            </Box>
          )}
          {!reservationInProgress && reservationDate !== today && (
            <Box gap={0.25} display='flex'>
              <Icon type='calendar' color={theme.palette.typography.info} size={16} />
              <Typography>{reservationDateText}</Typography>
            </Box>
          )}
          {reservationInProgress && (
            <Box gap={0.25} display='flex'>
              <Icon type='timer' color={theme.palette.typography.info} size={16} />
              <Typography>
                {minutesSinceStart > 0 ? durationString : '-'}
              </Typography>
            </Box>
          )}
          {!reservationWaitList && (
            <Box gap={0.25} display='flex'>
              <Icon type='clock' color={theme.palette.typography.info} size={16} />
              <Typography>
                {moment(reservation.from).format('HH:mm')}-{moment(reservation.to).format('HH:mm')}
              </Typography>
            </Box>
          )}
          {reservationWaitList && (
            <Box gap={0.25} display='flex'>
              <Icon type='clock' color={theme.palette.typography.info} size={16} />
              <Typography>
                {moment(reservation.createdAt).format(reservationDate === today ? 'HH:mm' : 'dd.MM HH:mm')}
              </Typography>
            </Box>
          )}
        </Box>
        {!reservationWaitList && ['enterprise', 'pro'].includes(activePlace.billType) && reservation.hall && (
          <Box display='flex' alignItems='center' justifyContent='space-between' gap={1} mt={0.5}>
            <Box display='flex' gap={1} minWidth={0}>
              <Typography color='typography.info' noWrap>
                {t('hall')}:
              </Typography>
              <Typography noWrap sx={{ color: getReservationColor(reservation.event) }}>
                {reservation.hall?.name}
              </Typography>
            </Box>
            {reservation.spots.length > 0 && (
              <Box display='flex' gap={1} minWidth={0}>
                <Typography color='typography.info' noWrap>
                  {t('table')}:
                </Typography>
                <Typography noWrap sx={{ color: getReservationColor(reservation.event) }}>
                  {reservation.spots.map(s => s.label).join(', ')}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {(reservationWaitList || newReservation || reservationAccepted || reservationInProgress) && (
        <Box flexGrow={0} flexShrink={0} flexBasis={40}>
          {reservationWaitList && (
            <>
              <StyledActionIcon primary onClick={acceptReservation}>
                <Icon type='check' color='white' />
              </StyledActionIcon>
              <StyledActionIcon onClick={deleteFromWaitList}>
                <Icon type='close' />
              </StyledActionIcon>
            </>
          )}
          {newReservation && (
            <>
              <StyledActionIcon primary onClick={acceptReservation}>
                <Icon type='check' color='white' />
              </StyledActionIcon>
              <StyledActionIcon onClick={rejectReservation}>
                <Icon type='close' />
              </StyledActionIcon>
            </>
          )}
          {reservationAccepted && !requireConfirmation && (
            <>
              <StyledActionIcon primary onClick={moveToInProgress}>
                <Icon type='reservationChair' color='white' />
              </StyledActionIcon>
              <StyledActionIcon onClick={skipReservation}>
                <Icon type='userCancel' />
              </StyledActionIcon>
            </>
          )}
          {reservationAccepted && requireConfirmation && (
            <>
              <StyledActionIcon
                onClick={confirmAdditionalConfirmation}
                style={{ backgroundColor: theme.palette.primary.light }}
              >
                <Icon type='check' color='white' />
              </StyledActionIcon>
              <StyledActionIcon onClick={cancelReservation}>
                <Icon type='close' color={theme.palette.primary.main} />
              </StyledActionIcon>
            </>
          )}
          {reservationInProgress && (
            <>
              <StyledActionIcon primary onClick={finishReservation}>
                <Icon type='finish' color='white' />
              </StyledActionIcon>
              <StyledActionIcon onClick={cancelReservation}>
                <Icon type='close' />
              </StyledActionIcon>
            </>
          )}
        </Box>
      )}
      <RejectReasonModal
        open={rejectModalOpen}
        onClose={closeRejectModal}
        reservation={reservation}
        activePlace={activePlace}
      />
      <AcceptReservationModal
        open={acceptModalOpen}
        onClose={closeAcceptModal}
        activePlace={activePlace}
        confirm={confirmAcceptReservation}
        reservation={reservation}
      />
      <ConfirmModal
        confirmTitle={t('accept')}
        open={skipModalOpen}
        onClose={closeSkipModal}
        confirm={confirmSkipReservation}
        title={t('skippingReservation')}
        content={<Typography>{t('confirmSkipReservation')}</Typography>}
      />
      <FinishReservationModal
        open={finishModalOpen || updateBillModalOpen}
        onClose={() => {
          closeFinishModal();
          closeUpdateBillModal();
        }}
        reservation={reservation}
        activePlace={activePlace}
        confirmFinishReservation={confirmFinishReservation}
        confirmUpdateBill={confirmUpdateBill}
        updating={updateBillModalOpen}
      />
      <ConfirmModal
        confirmTitle={t('accept')}
        open={cancelModalOpen}
        onClose={closeCancelModal}
        confirm={confirmCancelReservation}
        title={t('cancellingReservation')}
        content={<Typography>{t('confirmCancelReservation')}</Typography>}
      />
      <ConfirmModal
        confirmTitle={t('accept')}
        open={inProgressModalOpen}
        onClose={closeInProgressModal}
        confirm={confirmMoveToInProgress}
        title={t('movingToInProgress')}
        content={<Typography>{t('confirmMoveToInProgress')}</Typography>}
      />
      <ConfirmModal
        confirmTitle={t('accept')}
        open={waitListModalOpen}
        onClose={closeWaitListModal}
        confirm={confirmDeleteFromWaitList}
        title={t('deleteFromWaitList')}
        content={<Typography>{t('confirmDeleteFromWaitList')}</Typography>}
      />
      <ConfirmModal
        confirmTitle={t('accept')}
        open={backToAcceptedModalOpen}
        onClose={closeBackToAcceptedModal}
        confirm={confirmBackToAccepted}
        title={t('backToAccepted')}
        content={<Typography>{t('confirmBackToAccepted')}</Typography>}
      />
      {historyModalOpen && <ReservationHistoryModal reservation={reservation} onClose={closeHistoryModal} />}
      {menuPreviewOpen &&
        <PreviewMenuModal onClose={closeMenuPreview} order={reservation.bill.data.order} editMenu={editMenu} showEdit />
      }
    </StyledRootBox>
  );
};

export default memo(ReservationCard);
