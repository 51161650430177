import React, { memo } from 'react';
import Modal from '@components/Modal/Modal';
import mova from 'mova';
import { useSelector } from "react-redux";
import { dishSelectors } from "state/ducks/dish";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../../Button/Button";
import DishCard from "./DishCard";

const t = mova.ns('components.PreviewMenuModal');

const MenuModal = ({ onClose, order, showEdit, editMenu }) => {
  const dishes = useSelector(dishSelectors.getDishes());
  const orderedDishes = order?.dishes || [];
  const getDish = (id) => dishes.find(d => d.id === id);
  const orderTotal = orderedDishes.reduce((acc, val) => acc + val.count * getDish(val.dishId).price, 0);
  const getSelected = (dish) => orderedDishes.find(d => d.dishId === dish.id)?.count;

  return (
    <Modal
      PaperProps={{ sx: { height: '90vh' } }}
      open
      onClose={onClose}
      title={t('title')}
      actions={
        <Box width='100%' display='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap' gap={2}>
          <Box>
            {showEdit && <Button size='large' onClick={editMenu}>{t('edit')}</Button>}
          </Box>
          <Box display='flex' alignItems='center' gap={1}>
            <Typography variant='body0'>{t('total')}:</Typography>
            <Typography sx={{ fontSize: 24, fontWeight: 600 }}>{orderTotal} {t('uah')}</Typography>
          </Box>
        </Box>
      }
      maxWidth='md'
    >
      <Box height='100%' display='flex' flexDirection='column' gap={2} sx={{ overflow: 'hidden' }}>
        <Box display='flex' flexDirection='column' gap={1} sx={{ overflow: 'auto' }}>
          {orderedDishes.map(d => <DishCard key={d.dishId} dish={getDish(d.dishId)} getSelected={getSelected} />)}
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(MenuModal);
