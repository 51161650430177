import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/Modal/Modal';
import mova from 'mova';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Icon from "../../Icon/Icon";
import { useSelector } from "react-redux";
import { dishSelectors } from "state/ducks/dish";
import Box from "@mui/material/Box";
import ScrollableMenu from "../../ScrollableMenu/ScrollableMenu";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "../../Button/Button";
import { clone } from "utils/utils";
import DishCard from "./DishCard";
import PreviewMenuModal from "./PreviewMenuModal";
import useModal from "utils/hooks/useModal/useModal";

const t = mova.ns('components.MenuModal');

const StyledTextField = styled(OutlinedInput)(({ theme }) => ({
  '&.MuiOutlinedInput-root': {
    height: theme.spacing(5),
    borderRadius: theme.shape.borderRadiusSmall,
    backgroundColor: 'white',
    borderColor: theme.palette.typography.light,
  },
}));

const MenuModal = ({ onClose, order, setOrder, initialPrepayment }) => {
  const [search, setSearch] = useState('');
  const [orderedDishes, setOrderedDishes] = useState(clone(order?.dishes) || []);
  const [prepayment, setPrepayment] = useState(initialPrepayment || "");
  const [menuPreviewOpen, openMenuPreview, closeMenuPreview] = useModal(false);
  const dishes = useSelector(dishSelectors.getDishes());
  const menus = useSelector(dishSelectors.getMenus());
  const menuItems = [...menus].sort((c1, c2) => c1.order - c2.order).map(c => ({ label: c.name, path: c.slug }));
  const location = useLocation();
  const history = useHistory();
  const urlParams = queryString.parse(location.search);

  const getDish = (id) => dishes.find(d => d.id === id);

  const orderTotal = orderedDishes.reduce((acc, val) => acc + val.count * getDish(val.dishId).price, 0);

  const onClear = () => {
    setSearch('');
  }

  useEffect(() => {
    if (!urlParams.path && menus.length) {
      const updatedParams = { ...urlParams, path: menus[0].slug };
      history.push(`${location.pathname}?${queryString.stringify(updatedParams)}`);
    }
  }, []);

  const filteredDishes = search
    ? dishes.filter(d => d.name.toLowerCase().includes(search.toLowerCase()))
    : dishes.filter(d => d.menus.some(m => m.slug === urlParams.path));

  const getSelected = (dish) => orderedDishes.find(d => d.dishId === dish.id)?.count;

  const setSelected = (dish, newVal) => {
    if (newVal === 0) {
      setOrderedDishes(orderedDishes.filter(d => d.dishId !== dish.id))
      return;
    }

    if (getSelected(dish)) {
      const index = orderedDishes.findIndex(d => d.dishId === dish.id);
      const newOrder = clone(orderedDishes);
      newOrder[index].count = newVal;
      setOrderedDishes([...newOrder]);
    } else {
      setOrderedDishes([...orderedDishes, { dishId: dish.id, count: newVal }]);
    }
  }

  return (
    <Modal
      PaperProps={{ sx: { height: '90vh' } }}
      open
      onClose={onClose}
      title={t('title')}
      actions={
        <Box display='flex' alignItems='center' justifyContent='flex-end' flexWrap='wrap' gap={2}>
          <Box display='flex' alignItems='center' gap={1}>
            <Typography variant='body0'>{t('total')}:</Typography>
            <Typography sx={{ fontSize: 24, fontWeight: 600 }}>{orderTotal} {t('uah')}</Typography>
          </Box>
          <Button variant='outlined' color='primary' size='large' onClick={onClose}>{t('cancel')}</Button>
          <Button size='large' onClick={() => setOrder(orderedDishes, orderTotal, prepayment)}>{t('save')}</Button>
        </Box>
      }
      maxWidth='md'
    >
      <Box height='100%' display='flex' flexDirection='column' gap={2} sx={{ overflow: 'hidden' }}>
        <Box display='flex' alignItems='center' gap={2}>
          <StyledTextField
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t('search')}
            startAdornment={
              <InputAdornment position='start'>
                <Icon type='search' />
              </InputAdornment>
            }
            endAdornment={
              !!search && <InputAdornment position='end'>
                <IconButton edge='end' onClick={onClear}>
                  <Icon type='close' />
                </IconButton>
              </InputAdornment>
            }
          />
          <Box display='flex' alignItems='center' gap={2} width='100%'>
            <StyledTextField
              placeholder={t('prepayment')}
              type='number'
              value={prepayment}
              onChange={(e) => setPrepayment(e.target.value)}
              sx={{ flexGrow: 1 }}
            />
            {orderedDishes.length > 0 && (
              <IconButton onClick={openMenuPreview}>
                <Icon type='eye' />
              </IconButton>
            )}
          </Box>
        </Box>
        <ScrollableMenu items={menuItems} />
        <Box display='flex' flexDirection='column' gap={1} sx={{ overflow: 'auto' }}>
          {filteredDishes.map(d => (
            <DishCard key={d.id} dish={d} getSelected={getSelected} setSelected={setSelected} editMode />
          ))}
        </Box>
      </Box>
      {menuPreviewOpen && <PreviewMenuModal onClose={closeMenuPreview} order={{ dishes: orderedDishes }} />}
    </Modal>
  );
};

MenuModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default memo(MenuModal);
