import React, { memo } from 'react';
import Card from "../../Card/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { lineClamp } from "../../../../utils/styles";
import Icon from "../../Icon/Icon";
import SelectQuantity from "../../SelectQuantity/SelectQuantity";
import { useTheme } from "@mui/material";
import mova from 'mova';

const t = mova.ns('components.DishCard');

const DishCard = ({ dish, getSelected, setSelected, editMode, ...rest }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        flexShrink: 0,
        p: { xs: 1, sm: 2 },
        display: 'flex',
        gap: 1,
        borderRadius: '8px',
        border: `1px solid ${theme.palette.typography.light}`,
        boxShadow: 'none',
      }}
      {...rest}
    >
      <Box
        component='img'
        src={dish.photoUrl || '/images/no-photo.jpg'}
        width={{ xs: 48, sm: 78 }}
        height={{ xs: 48, sm: 78 }}
        flexBasis={{ xs: 48, sm: 78 }}
        flexShrink={0}
        alt={dish.name}
        sx={{ objectFit: 'cover' }}
      />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        flexGrow={1}
        flexShrink={1}
        gap={0.5}
      >
        <Box>
          <Typography variant='h3' noWrap>{dish.name}</Typography>
          <Typography style={lineClamp(3)} color='primary.light' variant='caption'>{dish.description}</Typography>
        </Box>
        <Box display='flex' alignItems='center' gap={1}>
          <Typography variant='caption' color='primary.light'>{dish.weight}</Typography>
          {dish.variables?.top && <Icon type='top' size={16} />}
          {dish.variables?.hot && <Icon type='hot' size={16} />}
          {dish.variables?.vegetarian && <Icon type='vegetarian' size={16} />}
        </Box>
      </Box>
      <Box flexBasis={80} flexGrow={0} flexShrink={0} display='flex' alignItems='center'>
        <Typography variant='h3'>{dish.price} {t('uah')}</Typography>
      </Box>
      <Box display='flex' alignItems='center' flexBasis={110} flexGrow={0} flexShrink={0} justifyContent='flex-end'>
        {editMode && <SelectQuantity selected={getSelected(dish)} setSelected={(val) => setSelected(dish, val)} />}
        {!editMode && <Typography variant='h3'>{t('qty')}: {getSelected(dish)}</Typography>}
      </Box>
    </Card>
  );
};

export default memo(DishCard);
